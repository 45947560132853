<template>
  <v-container>
      <section class="plan-card" v-if="!cryptoOrderData">
        <div class="plan">
          <div class="plan-pack">
          <h1>{{ name }}</h1>
          <h3>{{ clicks }} clicks</h3>
          </div>
          <div class="plan-price">
            <strong>${{ printPrice }}</strong>/ month
          </div>
          <div class="plan-slider">
            <input
              type="range"
              min="1"
              max="2"
              value="1"
              class="slider"
              id="myRange"
              v-model="plan"
              :class="sliderClass"
            />
          </div>

        </div>
        <!--<div class="plan-billing">
          <div>Monthly Billing</div>
          <div>
            <label class="switch-price">
              <input type="checkbox" v-model="yearlyBilling" />
              <span class="slider round"></span>
            </label>
          </div>
          <div>
            Yearly Billing
            <div>-25%<span> discount</span></div>
          </div>
        </div>-->
        <div class="plan-card-footer">
          <ul class="plan-card-footer-benefits">
            <li v-for="feature in features" v-bind:key="feature">
              {{ feature }}
            </li>
          </ul>
          <div class="plan-card-footer-action">
            <div class="action-wrapper">
              <v-radio-group class="pay-select mt-0 pt-0" v-model="pay">
                <template v-slot:label>
                  <div>Subscribe <strong>with</strong></div>
                </template>
                <v-radio value="stripe" color="indigo darken-3">
                  <template v-slot:label>
                    <div>Card using <strong class="stripe-text">Stripe</strong></div>
                  </template>
                </v-radio>
                <v-radio value="binance-pay">
                  <template v-slot:label>
                    <div>Сryptocurrency using <strong class="binance-text">Binance Pay</strong></div>
                  </template>
                </v-radio>
                <v-radio value="coinbase">
                  <template v-slot:label>
                    <div>Сryptocurrency using <strong class="coinbase-text">Coinbase</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>

              <div v-if="pay === 'binance-pay'">
                <div>Select <strong>cryptocurrency</strong></div>
                <v-select
                  style="border-radius:20px"
                  class="cryptocurrency"
                  :items="cryptocurrencies"
                  v-model="cryptocurrency"
                  label=""
                  attach dense outlined
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                  <div>
                      <img :src="item.image">
                      <span>{{ item.name }}</span>
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div>
                      <img :src="item.image">
                      <span>{{ item.name }}</span>
                    </div>
                  </template>
                </v-select>
              </div><br>


              <a href="#" @click="subscribe(name)" class="btn w-100" style="background:black; color:white; font-size:15px; font-weight:500; padding:15px" :disabled="loading">Subscribe</a>


            </div><br><br>

            <div class="d-flex align-items-center justify-content-center" v-if="loading"><br><br>
              <v-progress-circular
                indeterminate
                color="#10d5c2"
              ></v-progress-circular>
            </div>

          </div>
        </div>
      </section>
      <section class="plan-card binance" v-if="cryptoOrderData">
        <div class="d-flex align-items-center justify-content-center" v-if="loading">
          <v-progress-circular
            indeterminate
            color="#10d5c2"
          ></v-progress-circular>
        </div>
        <div class="" v-if="cryptoOrderData && !loading">
          <h3 class="title">Crypto Order Status</h3><br>
          <div class="w-100">Open <strong><a style="text-decoration:underline; color:#3ad5c2" href="https://accounts.binance.com/en/register?ref=OBY61NNG">Binance mobile app</a></strong> <br> Scan this code to make payment. <br><br>  You will pay <strong>{{ printPrice }}$</strong> once. ( No rebill )</div>
          <div class="d-flex align-items-center">
            <v-simple-table dense class="my-3">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Payment Status</td>
                    <td>{{cryptoOrderData.status}}</td>
                  </tr>
                  <tr>
                    <td>Transaction ID</td>
                    <td>{{cryptoOrderData.transactionId}}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{{cryptoOrderData.totalFee}}</td>
                  </tr>
                  <tr>
                    <td>Cryptocurrency</td>
                    <td>{{cryptoOrderData.currency}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-img width="220" style="max-width: 220px; margin: 0 auto;" :src="cryptoOrderData.qrcodeLink"></v-img>
          </div>
          <b-alert v-if="cryptoOrderData.status !== 'paid'" show variant="light"> <v-icon>info</v-icon>  Automatically check payment status after <strong>{{autoCheckSeconds}}s</strong>. </b-alert>
        </div>
      </section>
  </v-container>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

let interval = null;

export default {
  name: "Billing",

  data() {
    return {
      sessionUrl: '',
      currentPlan: '',
      plan: 2,
      activeplan: {},
      price: 0,
      clicks: "",
      name : "",
      features :[],
      yearlyBilling: false,
      sliderClass: "slider-5",
      plans: [
        // { id: 1, clicks: "100K", price: 99, name:"plus" , features: ["10 campaigns","DNS cloaking","JS cloaking","Reverse proxy"] },
        { id: 1, clicks: "500K", price: 499, name:"pro", features: ["Unlimited campaigns","DNS cloaking","JS cloaking", "Reverse proxy","One click lander hosting","Support"]},
        { id: 2, clicks: "1M", price: 999, name:"agency" , features: ["Unlimited campaigns","DNS cloaking","JS cloaking","Reverse proxy","One click lander hosting","API Access","Priority support"]}
      ],
      cryptocurrencies: [
        { name: 'BUSD', image: '/media/svg/crypto/busd.svg', value: 'BUSD' },
        { name: 'USDT', image: '/media/svg/crypto/usdt.svg', value: 'USDT' }
      ],
      pay: 'stripe',
      cryptocurrency: 'USDT',
      cryptoOrderData: null,
      loading: false,
      autoCheckSeconds: 60
    }
  },

  methods: {
    async getStripeSessionUrl(plan) {
      const result = await ApiService.query(`/stripe/portal-session?plan=${plan}`)
        .then(res => res.data)
        .catch(() => null)

      if (result && result.url) {
        // window.open(result.url, '_blank').focus();
        window.location.replace(result.url);
      }
    },

    async getCoinbaseCheckoutUrl(plan) {
      const result = await ApiService.query(`/coinbase/checkout?plan=${plan}`)
        .then(res => res.data)
        .catch(() => null)

      if (result && result.url) {
        // window.open(result.url, '_blank').focus();
        window.location.replace(result.url);
      }
    },

    async checkTransactionStatus(transactionId) {
      const result = await ApiService.query(`/binance/status?transactionId=${transactionId}`)
        .then(res => res.data)
        .catch(() => null)

      if (result && result.status && this.cryptoOrderData) {
        this.cryptoOrderData.status = result.status;
      }
    },

    subscribe(plan) {
      if (this.pay === 'stripe') {
        this.getStripeSessionUrl(plan);
      } else if (this.pay === 'coinbase') {
        this.getCoinbaseCheckoutUrl(plan);
      } else {
        this.getQRCode(plan)
      }
    },

    async getQRCode(plan) {
      this.loading = true;

      const result = await ApiService.post(`/binance/order`, {
        plan,
        cryptocurrency: this.cryptocurrency
      })
        .then(res => res.data)
        .catch(() => null)

      this.cryptoOrderData = result;
      this.autoCheckSeconds = 10;
      this.loading = false;
    },


  getplan() {
      this.activeplan = this.plans.find((el) => {
        return el.id == this.plan;
      });
      this.price = this.yearlyBilling
        ? (
            this.activeplan.price.toFixed(2) -
            (this.activeplan.price.toFixed(2) / 100) * 50
          ).toFixed(2)
        : this.activeplan.price.toFixed(2);
      this.clicks = this.activeplan.clicks;
      this.name = this.activeplan.name;
      this.features = this.activeplan.features;
      this.sliderClass = "slider-" + this.plan;
    },
  },


  computed: {
    ...mapGetters(["userdata", "isAuthenticated"]),

    printPrice() {
      return this.price;
    },

    printViews() {
      return this.activeplan[0].clicks;
    },


  },


  mounted() {
    if (!this.isAuthenticated) {
      return this.$router.push({ name: "login" });
    }

    if (this.userdata && this.userdata.plan) {
      this.currentPlan = this.userdata.plan;
    }

    this.getplan();

    interval = setInterval(() => {
      if (this.cryptoOrderData) {
        this.autoCheckSeconds = this.autoCheckSeconds - 1;

        if (this.autoCheckSeconds <= 0) {
          if (this.cryptoOrderData.status !== 'paid') {
            this.checkTransactionStatus(this.cryptoOrderData.transactionId);
          }

          this.autoCheckSeconds = 60;
        }
      }
    }, 1000)
  },

  destroyed() {
    clearInterval(interval);
    interval = null;
  },

  watch: {
    plan: function () {
      this.getplan();
      this.cryptoOrderData = null;
    },
    yearlyBilling: function () {
      this.getplan();
    },
  },




};
</script>

<style scoped>


@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@600;800&display=swap");
* {
  box-sizing: border-box;
}

h2,
h3 {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #858fad;
  text-align: center;
  padding: 0 2em;
  margin: 0;
  background-color: #fafbff;
  background-image: url("https://raw.githubusercontent.com/Timothy1982/interactive-pricing-component/main/assets/images/bg-pattern.svg");
  background-position: top left;
  background-repeat: no-repeat;
}

body::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 449px;
  top: 0;
  left: 50%;
  background-color: #f1f5fe;
  z-index: -1;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 19.15em;
  background-image: url("https://raw.githubusercontent.com/Timothy1982/interactive-pricing-component/main/assets/images/pattern-circles.svg");
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 600px) {
  header {
    height: 21.9em;
  }
}
header h2 {
  color: #293356;
  font-size: 1.7em;
  font-weight: 800;
  line-height: 1;
  padding: 1rem 0;
}
@media screen and (min-width: 600px) {
  header h2 {
    font-size: 2.45em;
  }
}
header h3 {
  font-size: 1.1em;
  font-weight: 600;
  max-width: 21ch;
}
@media screen and (min-width: 600px) {
  header h3 {
    font-size: 1.25em;
    max-width: 100%;
  }
}

.plan-card {
  width: 100%;
  max-width: 45em;
  margin: 50px auto 20px auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 10px 35px rgba(205, 215, 238, 0.5);
}

.plan-card.binance {
  padding: 20px;
  margin: 0 auto;
}

.plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2.65em 2em 0;
}
@media screen and (min-width: 600px) {
  .plan {
    padding: 3.75em 3.75em 0;
  }
}
.plan-pack,
.plan-price {
  width: 100%;
  flex-shrink: 0;
}
@media screen and (min-width: 600px) {
  .plan-pack,
  .plan-price {
    width: 50%;
  }
}
.plan-pack {
  font-size: 1.1em;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}
.plan-pack h3 {
  font-size: inherit;
  font-weight: inherit;
}
@media screen and (min-width: 600px) {
  .plan-pack {
    text-align: left;
  }
}
.plan-price {
  font-size: 1.2em;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 1;
}
@media screen and (min-width: 600px) {
  .plan-price {
    justify-content: flex-end;
    order: inherit;
  }
}
.plan-price strong {
  color: #293356;
  font-size: 2.5rem;
  padding-right: 0.5rem;
}
.plan-slider {
  width: 100%;
  flex-shrink: 0;
  padding: 2.15em 0;
}
.plan-slider input {
  width: 100%;
  height: 0.65em;
  -webkit-appearance: none;
  background: transparent;
  background-color: #cdd7ee;
  border-radius: 9999em;
}
.plan-slider input.slider-1 {
  background-image: linear-gradient(
    to right,
    #10d5c2 0%,
    #cdd7ee 1%,
    #cdd7ee 100%
  );
}
/* .plan-slider input.slider-2 {
  background-image: linear-gradient(
    to right,
    #10d5c2 0%,
    #10d5c2 49%,
    #cdd7ee 50%,
    #cdd7ee 100%
  );
} */
.plan-slider input.slider-2 {
  background-image: linear-gradient(
    to right,
    #10d5c2 0%,
    #10d5c2 100%
  );
}
.plan-slider input.slider-3 {
  background-image: linear-gradient(
    to right,
    #10d5c2 0%,
    #10d5c2 100%
  );
}
.plan-slider input.slider-4 {
  background-image: linear-gradient(
    to right,
    #10d5c2 0%,
    #10d5c2 74%,
    #cdd7ee 75%,
    #cdd7ee 100%
  );
}
.plan-slider input.slider-5 {
  background-image: linear-gradient(
    to right,
    #10d5c2 0%,
    #10d5c2 99%,
    #cdd7ee 100%
  );
}
.plan-slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 3.25em;
  width: 3.25em;
  margin-top: -2px;
  border-radius: 100%;
  background-color: #10d5c2;
  background-image: url("https://raw.githubusercontent.com/Timothy1982/interactive-pricing-component/main/assets/images/icon-slider.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  box-shadow: 0 15px 20px rgba(16, 213, 194, 0.5);
  transition: background-color 200ms ease-in-out;
}
.plan-slider input::-webkit-slider-thumb:hover {
  background-color: #24aea0;
  box-shadow: 0 15px 20px rgba(16, 213, 194, 0.75);
}
.plan-slider input::-webkit-slider-thumb:active {
  cursor: grabbing;
}
.plan-slider input::-moz-range-thumb,
.plan-slider input::-ms-thumb {
  height: 3.25em;
  width: 3.25em;
  border-radius: 100%;
  background-color: #10d5c2;
  background-image: url("https://raw.githubusercontent.com/Timothy1982/interactive-pricing-component/main/assets/images/icon-slider.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.plan-slider input:focus {
  outline: none;
}

.plan-billing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.75em;
  margin-bottom: 3.15em;
}
.plan-billing div:nth-of-type(2) {
  width: 5em;
  flex-shrink: 0;
}
.plan-billing div:nth-of-type(1),
.plan-billing div:nth-of-type(3) {
  font-size: 0.875em;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .plan-billing div:nth-of-type(1),
  .plan-billing div:nth-of-type(3) {
    font-size: 1em;
  }
}
.plan-billing div:nth-of-type(1) {
  text-align: right;
}
.plan-billing div:nth-of-type(3) {
  text-align: left;
}
.plan-billing div:nth-of-type(3) div {
  display: inline;
  color: #ff8c66;
  font-size: 0.8em;
  margin-left: 0.75em;
  padding: 0.25em 0.75em;
  background-color: #feece7;
  border-radius: 9999em;
}
.plan-billing div:nth-of-type(3) div span {
  display: none;
}
@media screen and (min-width: 600px) {
  .plan-billing div:nth-of-type(3) div span {
    display: inline;
  }
}

.switch-price {
  position: relative ;
  display: inline-block;
  width: 3.35em;
  height: 1.75em;
}
.switch-price input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-price input:checked + .slider {
  background-color: #72eed7;
}
.switch-price input:checked + .slider::before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
}
.switch-price .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cdd7ee;
  border-radius: 9999em;
  transition: 400ms;
}
.switch-price .slider::before {
  content: "";
  position: absolute;
  left: 5px;
  bottom: 3px;
  height: 1.25em;
  width: 1.25em;
  border-radius: 100%;
  background-color: white;
  transition: transform 150ms ease, background-color 150ms ease;
}

.plan-card-footer {
  display: flex;
  flex-direction: column;
  padding: 1em 2em;
  border-top: 1px solid #f1f0f5;
}
@media screen and (min-width: 600px) {
  .plan-card-footer {
    flex-direction: row;
  }
}
.plan-card-footer-benefits,
.plan-card-footer-action {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.plan-card-footer-benefits {
  list-style-image: url("https://raw.githubusercontent.com/Timothy1982/interactive-pricing-component/main/assets/images/icon-check.svg");
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 600px) {
  .plan-card-footer-benefits {
    display: initial;
    margin: 1.5em 0 1.5em 3.75em;
  }
}
.plan-card-footer-benefits li {
  text-align: left;
  margin: 0;
  padding: 0.5em 0.5em;
}
.plan-card-footer-action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.065em;
}

.btn {
  color: #ccd9ff;
  text-decoration: initial;
  font-size: 1em;
  line-height: 1;
  padding: 1.15em 4em;
  background-color: #293356;
  border-radius: 9999em;
  transition: color 200ms ease;
}
.btn:hover,
.btn:focus {
  color: white;
  background-color: #293356;
}

.attribution {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7.5em;
}

.attribution a {
  color: #293356;
}

.attribution a:hover {
  color: rgba(41, 51, 86, 0.75);
}

.cryptocurrency .v-list-item div, .cryptocurrency .v-select__selections div {
  display: flex;
  align-items: center;
}

.cryptocurrency .v-list-item img, .cryptocurrency .v-select__selections img {
  width: 22px;
  margin-right: 7px;
}

::v-deep .pay-select .v-icon {
  color: #10d5c2 !important;
}

::v-deep .pay-select .v-input--selection-controls__input {
  margin-right: 7px;
}

::v-deep .pay-select label {
  margin: 0;
}

/* .stripe-text {
  color: #7a73ff;
  text-shadow: 0px 0px 1px #0c0c0c;
}

.binance-text {
  color: #fcd534;
  text-shadow: 0px 0px 1px #0c0c0c;
} 

.coinbase-text {
  color: #fcd534;
  text-shadow: 0px 0px 1px #0c0c0c;
}
*/
</style>
